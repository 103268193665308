















































import Vue from "vue";
import Component from "vue-class-component";
import { Route } from "vue-router";
import { Ref, Watch } from "vue-property-decorator";
import ApiButton from "@/vue/components/ApiButton.vue";
import { UserStatus } from '@/model/Enums';
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { VForm } from "@/vForm";

interface IResetPassword {
    key: string;
    state: StateType;
    userStatus: UserStatus;
    email: string;
    password: string;
    passwordRepeat: string;
    message: string;
}

enum StateType {
    None = 0,
    Ready = 1,
    Expired = 2,
    Success = 3,
    ValidationFailed = 4,
    Failed = 5
}

@Component({
    components: { ApiButton }
})
export default class ResetPassword extends Vue {

    //
    // -- lifecycle hooks, etc.
    //

    mounted(): void {
        this.onKeyChanged(this.$router.currentRoute.params.key);
    }

    // 
    // -- watchers
    //

    // if the group ID changes in the URL, the component *will not* reload - so we need to handle it here
    @Watch("$route", { immediate: true, deep: true })
    onRouteChanged(route: Route): void {
        this.onKeyChanged(route.params.key);
    }

    //
    // -- properties
    //

    @Ref("passwordForm") readonly passwordForm!: VForm;

    resetPassword: IResetPassword = {
        key: "",
        state: StateType.None,
        userStatus: UserStatus.None,
        email: "",
        password: "",
        passwordRepeat: "",
        message: ""
    };

    get isMessage(): boolean {
        return !!this.resetPassword.message;
    }

    get messageVariant(): string {
        return this.resetPassword.state == StateType.Success ? "success" : "error";
    }

    get shouldShowForm(): boolean {
        return this.resetPassword.state === StateType.None ||
            this.resetPassword.state === StateType.Ready ||
            this.resetPassword.state === StateType.Failed ||
            this.resetPassword.state === StateType.ValidationFailed;
    }

    get isNew(): boolean {
        return this.resetPassword.userStatus !== UserStatus.Active;
    }

    //
    // -- validation
    //

    passwordValid: boolean = false;
    passwordErrorMessages: Array<string> = [];

    @Watch("resetPassword.password")
    private async validatePassword() {
        if (utils.isEmptyOrWhitespace(this.resetPassword.password)) {
            this.passwordErrorMessages = [];
            return;
        }

        const response: { isOkay: boolean; message: string } = await apiClient.get(`/api/user/validatePassword?password=${this.resetPassword.password}`);
        if (response.isOkay) {
            this.passwordErrorMessages = [];
        }
        else if (!response.isOkay && this.passwordErrorMessages.indexOf(response.message) == -1) {
            this.passwordErrorMessages = [];
            this.passwordErrorMessages.push(response.message);
            return;
        }
    }

    //
    // -- methods
    //

    private async onKeyChanged(key: string) {
        if (this.resetPassword.key === key) return;
        this.resetPassword.key = key;           
        const response: IResetPassword = await apiClient.post("/api/authentication/resetPasswordInit", this.resetPassword);
        this.resetPassword = response;
        this.passwordErrorMessages = [];
        this.passwordForm?.resetValidation();
        this.resetPassword.passwordRepeat = "";
    }

    async savePassword(): Promise<void> {
        await this.validatePassword();
        const isValid = this.passwordForm.validate() && this.passwordErrorMessages.length === 0;

        if (!isValid) {
            this.resetPassword.state = StateType.ValidationFailed;
            this.resetPassword.message = "Please enter new password"
            return;
        } 

        this.resetPassword.message = "";

        const response: IResetPassword = await apiClient.post("/api/authentication/resetPassword", this.resetPassword, "save-password");
        this.resetPassword = response;
    }

}
